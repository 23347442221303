import { render, staticRenderFns } from "./TestSiOSPoC.vue?vue&type=template&id=1ab0a68a&scoped=true&"
import script from "./TestSiOSPoC.vue?vue&type=script&lang=js&"
export * from "./TestSiOSPoC.vue?vue&type=script&lang=js&"
import style0 from "./TestSiOSPoC.vue?vue&type=style&index=0&id=1ab0a68a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab0a68a",
  null
  
)

export default component.exports